import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function OneIconBorder({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#EEE4FC" />
        <path
          d="M18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M17.6251 14.0779L15.75 19.3906H19.5002"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 16.8904V21.8906"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}
