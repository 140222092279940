import { createTheme } from "@mui/material";
import theme from "./generic";


const lightTheme = createTheme({
    ...theme,
    palette: {
        mode: "light",
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#252734"
        },
        text: {
            primary: "#000000",
            secondary: "#7E2AEA",
        },

        background: {
            default: "#F2F3F7",
        },
        lightPurple: {
            main: "#333647",
        },
        darkPurple: {
            main: "#252734",
        },
        brightPurple: {
            main: "#7E2AEA",
        },
        fadePurple: {
            main: "#C19AF5",
        },
        grey1: {
            main: "#434657",
        },
        grey2: {
            main: "#9A9AAF",
        },
        grey3: {
            main: "#4D4D4D",
        },
        grey4: {
            main: "#333647",
        },
        orange: {
            main: "#FB5607",
        },
        navbarbg: {
            main: "#FFFFFF",
        },
    }
});

export default lightTheme;