import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import abstraction from '../../assets/Quiz-main.png'
import SectionStyled from './SectionStyled';
import { Link, redirect } from 'react-router-dom';
import {setIsContactFormOpen} from "@root/contactForm";

export default function Component() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    return(
        <SectionStyled tag={'section'} bg={'#f2f3f7'} mwidth={'1160px'}
                       sxsect={{
                           height: isMobile ? '702px' : (isTablet ? '986px' : '660px'),
                       }}
                       sxcont={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           padding: isMobile ? "300px 16px 0 16px" : (isTablet ? "494px 40px 0 40px" : 0),
                           marginBottom: isMobile ? '55px' : (isTablet ? 0 : "55px"),
                       }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: "flex-start",
                    position: 'relative',
                }}
            >
                <Typography variant='h2'>
                    Pena Quiz
                </Typography>
                <Box
                    sx={{
                        maxWidth: isTablet ? '715px' : '420px',
                        minHeight: '64px',
                    }}>
                    <Typography variant='body1'>
                        Помогаем посетителю оставить заявку. <br style={{display: isTablet ? 'flex' : "none"}}/> Готовые шаблоны квизов с легкой установкой на любой сайт и социальные сети
                    </Typography>
                </Box>
                    {/*<Link */}
                    {/*    to="/list"*/}
                    {/*    style={{textDecoration: "none"}}>*/}
                <Button variant="contained"
                        onClick={() => setIsContactFormOpen(true)}
                >
                    Попробуйте бесплатно
                    
                </Button>
                    {/*</Link>*/}

                <Box
                    component={"img"}
                    src={abstraction}
                    sx={{
                        position: "absolute",
                        bottom: isMobile ? undefined : (isTablet? "138px" : "-291px"),
                        maxWidth: isMobile ? "403px" : "810px",
                        width: isMobile ? "100%" : undefined,
                        left: isMobile ? "-20px" : (isTablet? "54px" : "401px"),
                        top: isMobile ? "-345px" : undefined
                    }}
                />
            </Box>
        </SectionStyled>
    )
}