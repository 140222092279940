import React from 'react';
import {CssBaseline, useMediaQuery, useTheme} from "@mui/material";
import Header from './HeaderLanding';
import Footer from './FooterLanding';
import Hero from './Hero';
import Questions from './Questions';
import Counter from './Counter';
import Blog from './Blog';
import HowItWorks from './HowItWorks';
import BusinessPluses from './BusinessPluses';
import HowToUse from './HowToUse';
import WhatTheySay from './WhatTheySay';
import StartWithTemplates from './StartWithTemplates';
import WhatTheFeatures from './WhatTheFeatures';
import FullScreenDialog from "./headerMobileLanding";
import Collaboration from "./Collaboration";

export default function Landing() {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    return (
        <>
            <CssBaseline />
            <Header/>
            <Hero/>
            <Counter/>
            <Collaboration/>
            <HowItWorks/>
            <BusinessPluses/>
            <HowToUse/>
            {/*<WhatTheySay/>*/}
            {/*<StartWithTemplates/>*/}
            <WhatTheFeatures/>
            {/*<Blog/>*/}
            {/*<Questions/>*/}
            <Footer />
        </>

    )
}


