import { createTheme } from "@mui/material";

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        main: true;
        outmain: true;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 560,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    p1: "p",
                }
            },
        },
        MuiButton: {
            variants: [
                {
                    props: {
                        variant: 'contained' },
                    style: {
                        backgroundColor: "#7E2AEA",
                        padding: '13px 20px',
                        borderRadius: '8px',
                        color: '#ffffff',
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "#581CA7"
                        }

                    },
                },
                {
                    props: {
                        variant: 'outlined' },
                    style: {
                        backgroundColor: "#F2F3F7",
                        padding: '10px 20px',
                        borderRadius: '8px',
                        border: '1px solid #7E2AEA',
                        color: '#9A9AAF',
                        "&:hover": {
                            backgroundColor: "#581CA7",
                            border: '1px solid #581CA7',
                        }

                    },
                },
            ],
        },
    },
    typography: {
        h5: { // H2 в макете
            fontSize: "24px",
            lineHeight: "28.44px",
            fontWeight: 500,
        },
        button: {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 400,
            textTransform: "none",
        },
        body1: { // T1 в макете
            fontSize: "18px",
            lineHeight: "21.33px",
            fontWeight: 400,
        },
        body2: { // M1 в макете
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 500,
        },
        p1: { // P1 в макете
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 500,
        },
        fontFamily: [
            "Rubik",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});

theme.typography.h2 = {
    fontSize: "70px",
    lineHeight: "70px",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
        fontSize: "42px",
        lineHeight: "50px",
    }
};

theme.typography.h4 = { // H1 в макете
    fontSize: "36px",
    lineHeight: "42.66px",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        lineHeight: "28.44px",
    }
};

theme.typography.infographic = {
    fontSize: "80px",
    lineHeight: "94.8px",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
        fontSize: "50px",
        lineHeight: "59px",
        fontWeight: 400,
    }
};

export default theme;