import { Box } from "@mui/material";


interface Props {
    color: string;
}

export default function OneIconBorder({color}:Props) {

    return (
        <Box
            sx={{
                height: "30px",
                width: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
                <path d="M10.125 9.375L12.375 7.875V16.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </Box>
    );
}