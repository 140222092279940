import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function OneIconBorder({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#EEE4FC" />
        <path
          d="M18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M16.2696 15.3945C16.4382 14.9964 16.7392 14.6687 17.1216 14.4669C17.504 14.2651 17.9444 14.2015 18.3683 14.2871C18.7921 14.3726 19.1734 14.6018 19.4477 14.9361C19.7219 15.2704 19.8723 15.6892 19.8734 16.1216C19.8751 16.4951 19.7633 16.8604 19.5529 17.1691V17.1691L16.1211 21.75H19.8734"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}
