import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import SectionStyled from './SectionStyled';
import Button from "@mui/material/Button";
// import Desktop from '../../assets/LandingPict/Desktop.png';
import Desktop1 from './images/Frame 1171274552.png';
import Desktop2 from './images/Frame 1171274552-1.png';
import Desktop3 from './images/Frame 1171274552-2.png';
import Desktop4 from './images/Frame 1171274552-3.png';
import Desktop5 from './images/Frame 1171274552-4.png';
import Desktop6 from './images/Frame 1171274552-5.png';
import Desktop7 from './images/Frame 1171274552-6.png';


interface Props {
    children: React.ReactNode;
    image: string;
}

function BoxFich(props: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTabletOnly = useMediaQuery(theme.breakpoints.between(600, 1000))
    return(
        <Box
            sx={{
                maxWidth: isTabletOnly ? '801px' : '500px',
                minHeight: '350px',
                backgroundColor: '#252734',
                borderRadius: '12px',
                overflow: 'hidden',
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "space-between" : undefined,
                height: isMobile ? "450px" : undefined
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    minHeight: '50%',
                    padding: '20px',
                    flexWrap: 'wrap',
                    boxSizing: "border-box",
                    flexDirection: isTabletOnly ? "column" : "row",
                    gap: isMobile ? "16px" : undefined
                }}
            >
                {props.children}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                }}
            >
                <img src={props.image} width={'96%'}/>
            </Box>
        </Box>
    )
}

export default function Component () {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    const isTabletOnly = useMediaQuery(theme.breakpoints.between(600, 1000))
    const [open, setOpen] = useState(true);

    return (
        <SectionStyled tag={'section'} bg={'#333647'} mwidth={'1160px'}
                       sxsect={{minHeight: '2074px',}}
                       sxcont={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           minHeight: '2074px',
                           padding: isMobile ? "20px 16px 40px 16px" : isTablet ? "30px 40px" : "30px 0",
                           color: '#ffffff',
                           flexWrap: "wrap"
                       }}
        >
            {/*левая колонка*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isMobile ? "25px" : "120px",
                    paddingTop: "46px"
                }}
            >
                <Box
                    sx={{
                        maxWidth: '400px',
                        minHeight: '84px',
                        marginBottom: isMobile ? 0 : "-55px"
                    }}>
                    <Typography variant='h1'
                                sx={{
                                    fontSize:'36px',
                                    fontWeight:'500',
                                    lineHeight:'43px',
                                }}
                    >
                        Что по фичам?
                    </Typography>
                    <Typography
                        sx={{
                            fontSize:'18px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            margin: '20px 0 10px 0',
                        }}
                    >
                        Самый функциональный конструктор квизов
                    </Typography>
                </Box>
                <BoxFich image={Desktop1}>
                    <Box sx={{maxWidth: isTabletOnly ? '244px' : '174px'}}>
                        <Typography
                            sx={{
                                color: '#7e2aea',
                                fontSize: '24px',
                                fontWeight: '500',
                                lineHeight: '28.44px'
                            }}
                        >
                            01
                        </Typography>
                        <Typography variant={'h6'}
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}
                        >Привязка к вашему домену</Typography>
                    </Box>
                    <Box sx={{maxWidth: isTabletOnly ? '300px' : '230px'}}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21px',
                            }}
                        >
                            Привяжите квиз к своему брендовому домену и получайте
                            максимальную отдачу от каждой заявки
                        </Typography>
                    </Box>
                </BoxFich>
                <BoxFich image={Desktop2}>
                    <Box maxWidth={'227px'}>
                        <Typography
                            sx={{
                                color: '#7e2aea',
                                fontSize: '24px',
                                fontWeight: '500',
                                lineHeight: '28.44px'
                            }}
                        >03</Typography>
                        <Typography variant={'h6'}
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}
                        >Аналитика</Typography>
                    </Box>
                    <Box maxWidth={'230px'}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21px',
                            }}
                        >
                            Внутренняя аналитика считает конверсию квиза на каждом шаге,
                            показывает развернутую статистику по ответам и пользователям
                        </Typography>
                    </Box>
                </BoxFich>
                <BoxFich image={Desktop3}>
                    <Box maxWidth={'227px'}>
                        <Typography
                            sx={{
                                color: '#7e2aea',
                                fontSize: '24px',
                                fontWeight: '500',
                                lineHeight: '28.44px'
                            }}
                        >05</Typography>
                        <Typography variant={'h6'}
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}
                        >Режим анкетирования</Typography>
                    </Box>
                    <Box maxWidth={'230px'}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21px',
                            }}
                        >
                            Самый удобный инструмент для проведения исследований аудитории,
                            больше вас не сдерживают никакие ограничения.
                        </Typography>
                    </Box>
                </BoxFich>
                <BoxFich image={Desktop4}>
                    <Box maxWidth={'226px'}>
                        <Typography
                            sx={{
                                color: '#7e2aea',
                                fontSize: '24px',
                                fontWeight: '500',
                                lineHeight: '28.44px'
                            }}
                        >07</Typography>
                        <Typography variant={'h6'}
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}
                        >Интерактивные тесты</Typography>
                    </Box>
                    <Box maxWidth={'234px'}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21px',
                            }}
                        >
                            Тест увеличит вовлеченность аудитории, покажет правильные
                            или неправильные ответы и все это в игровой форме
                        </Typography>
                    </Box>
                </BoxFich>
            </Box>
            {/*правая колонка*/}

                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: isMobile ? "25px" : "106px",
                            gap: isMobile ? "25px" : "113px",
                            alignItems: "end"
                        }}
                    >
                        <BoxFich image={Desktop5}>
                            <Box maxWidth={'227px'}>
                                <Typography
                                    sx={{
                                        color: '#7e2aea',
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}
                                >02</Typography>
                                <Typography variant={'h6'}
                                            sx={{
                                                fontSize: '24px',
                                                fontWeight: '500',
                                                lineHeight: '28.44px'
                                            }}
                                >Повышение конверсии</Typography>
                            </Box>
                            <Box maxWidth={'233px'}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                    }}
                                >
                                    Конверсию можно увеличить, добавив тающую скидку,
                                    консультанта с подсказками или бонус, который пользователь получит в конце квиза
                                </Typography>
                            </Box>
                        </BoxFich>
                        <BoxFich image={Desktop6}>
                            <Box maxWidth={'227px'}>
                                <Typography
                                    sx={{
                                        color: '#7e2aea',
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}>04</Typography>
                                <Typography variant={'h6'}
                                            sx={{
                                                fontSize: '24px',
                                                fontWeight: '500',
                                                lineHeight: '28.44px'
                                            }}
                                >Расширенеие настройки дизайна</Typography>
                            </Box>
                            <Box maxWidth={'230px'}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                    }}
                                >
                                    Настройте цвета, шрифт и выберите формат стартовой страницы,
                                    чтобы сделать свой квиз уникальным
                                </Typography>
                            </Box>
                        </BoxFich>
                        <BoxFich image={Desktop7}>
                            <Box maxWidth={'220px'}>
                                <Typography
                                    sx={{
                                        color: '#7e2aea',
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '28.44px'
                                    }}
                                >06</Typography>
                                <Typography variant={'h6'}
                                            sx={{
                                                fontSize: '24px',
                                                fontWeight: '500',
                                                lineHeight: '28.44px'
                                            }}
                                >А/Б тестирование</Typography>
                            </Box>
                            <Box maxWidth={'230px'}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                    }}
                                >
                                    Без программистов и сложных настроек - создавайте разные версии квизов
                                    и сравнивайте их эффективность прямо внутри Опросника
                                </Typography>
                            </Box>
                        </BoxFich>
                </Box>
                <Box
                    sx={{
                        maxWidth: '500px',
                        minHeight: '390px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: isMobile ? "40px 0 0 0" : isTablet ? "70px 22px 0 20px" : "217px 22px 0 20px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column-reverse" : "column",
                            gap: "47px",
                            alignItems: "flex-start",
                        }}
                    >
                        <Box>
                            <Typography variant={'h6'}
                                        sx={{
                                            fontSize: '24px',
                                            fontWeight: '500',
                                            lineHeight: '28px',
                                            width: '60%',
                                        }}
                            >Первые 14 дней бесплатно</Typography>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    lineHeight: '21.33px',
                                    marginTop: '32px',
                                }}
                            >
                                Пробуйте, экспериментируйте, а наша отзывчивая техническая поддержка
                                поможет и проконсультирует в любой момент,
                                больше вы не останетесь 1 на 1 со своими проблемами.
                            </Typography>
                        </Box>
                        {/*<Button variant="outlined"*/}
                        {/*        onClick={() => setOpen((isOpened) => !isOpened)}*/}
                        {/*        sx={{*/}
                        {/*            textTransform: 'none',*/}
                        {/*            color: '#ffffff',*/}
                        {/*            backgroundColor: 'transparent',*/}
                        {/*            fontSize: '18px',*/}
                        {/*            borderRadius: '8px',*/}
                        {/*            borderColor: '#ffffff',*/}
                        {/*        }}*/}
                        {/*>Все возможности сервиса</Button>*/}
                    </Box>
                    {/*<Button variant="contained"*/}
                    {/*        sx={{*/}
                    {/*            textTransform: 'none',*/}
                    {/*            fontSize: '18px',*/}

                    {/*            borderRadius: '8px',*/}
                    {/*            padding: '10px 32px',*/}
                    {/*            marginTop: '27px',*/}
                    {/*        }}>*/}
                    {/*    Создать квиз</Button>*/}
                </Box>
            </Box>
        </SectionStyled>
    )
}