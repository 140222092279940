import React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Props {
    sxsect?: SxProps<Theme>;
    mwidth: string;
    padding?: string;
    tag: React.ElementType;
    bg: string;
    children: React.ReactNode;
    sxcont?: SxProps<Theme>;
}
export default function Section (props: Props) {

    return (
        <Box
            component={props.tag}
            backgroundColor={props.bg}
            sx={{
                width: '100%',
                fontFamily: 'Rubik',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...props.sxsect
            }}
        >
            <Box
                maxWidth={props.mwidth}
                sx={{
                    width: '100%',
                    ...props.sxcont
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
}