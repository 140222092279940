import React from 'react';
import Box from '@mui/material/Box';
import {Typography, useMediaQuery, useTheme} from "@mui/material";


export default function Counter() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    return(
        <Box
            component={"section"}
            sx={{
                width: '100%',
                fontFamily: 'Rubik',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                height: isMobile ? "606px" : isTablet ? "430px" : "208px",
                boxSizing: "border-box",
                padding: isTablet ? "0 40px" : undefined,
                backgroundColor: "#333647"
            }}
        >
            <Box
                sx={{
                    maxWidth: '1160px',
                    height: isMobile ? "587px" : isTablet ? "341px" : "190px",
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: isTablet ? 'start' : 'center',
                    flexWrap: 'wrap',
                    backgroundColor: '#7E2AEA',
                    color: '#ffffff',
                    borderRadius: '8px',
                    boxSizing: "border-box",
                    padding: isMobile ? '42px 60px 30px 35px' : isTablet ? '37px 99px 0px 100px' : '30px 60px 30px 20px',
                    width: '100%',
                    marginTop: isMobile ? "-50px" : isTablet ? "45px" : "-41px",
                    rowGap: isMobile ? undefined : (isTablet ? '51px' : undefined),
                }}
            >
                <Box
                    sx={{
                        width: isTablet ? '305px' : '203px',
                    }}
                >
                    <Typography variant='h3' fontSize={isMobile ? '42px' : '50px'} fontWeight={'500'}>14 дней</Typography>
                    <Typography> бесплатно, полный функционал</Typography>
                </Box>
                <Box
                    sx={{
                        width: isTablet ? '305px' : undefined
                    }}
                >
                    <Typography variant='h3' fontSize={isMobile ? '42px' : '50px'} fontWeight={'500'}>5 мин</Typography>
                    <Box
                        sx={{
                            width: isTablet ? '200px' : '131px',
                        }}
                    >
                        <Typography >для создания квиза</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: isTablet ? '305px' : undefined
                    }}
                >
                    <Typography variant='h3' fontSize={isMobile ? '42px' : '50px'} fontWeight={'500'}>482 800</Typography>
                    <Box
                        sx={{
                            width: isMobile? '200px' : (isTablet ? '250px' : '200px'),
                        }}
                    >
                        <Typography >квизов создано клиентами</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: isTablet ? '305px' : '177px',
                    }}
                >
                    <Typography variant='h3' fontSize={isMobile ? '42px' : '50px'} fontWeight={'500'}>760</Typography>
                    <Typography>готовых шаблонов для разных ниш</Typography>
                </Box>
            </Box>
        </Box>
    )
}