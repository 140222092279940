import { Box } from "@mui/material";


interface Props {
    color?: string;
}

export default function ({color}:Props) {

    return (
        <Box
            sx={{
                height: "36px",
                width: "36px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="6" fill="#7E2AEA"/>
<path d="M21.073 8H14.937C9.333 8 8 9.333 8 14.927V21.063C8 26.666 9.323 28 14.927 28H21.063C26.666 28 28 26.677 28 21.073V14.937C28 9.333 26.677 8 21.073 8ZM24.146 22.27H22.687C22.135 22.27 21.969 21.823 20.979 20.833C20.115 20 19.75 19.896 19.531 19.896C19.229 19.896 19.146 19.979 19.146 20.396V21.708C19.146 22.063 19.031 22.271 18.104 22.271C17.2043 22.2105 16.3319 21.9372 15.5586 21.4735C14.7853 21.0098 14.1332 20.3691 13.656 19.604C12.5232 18.194 11.7349 16.5391 11.354 14.771C11.354 14.552 11.437 14.354 11.854 14.354H13.312C13.687 14.354 13.822 14.521 13.969 14.906C14.677 16.99 15.885 18.802 16.375 18.802C16.563 18.802 16.645 18.719 16.645 18.25V16.104C16.583 15.125 16.063 15.042 16.063 14.688C16.0697 14.5946 16.1125 14.5075 16.1823 14.4452C16.2521 14.3828 16.3435 14.3501 16.437 14.354H18.729C19.042 14.354 19.146 14.51 19.146 14.885V17.781C19.146 18.094 19.281 18.198 19.375 18.198C19.563 18.198 19.708 18.094 20.052 17.75C20.7909 16.8489 21.3945 15.8451 21.844 14.77C21.8899 14.6409 21.9769 14.5303 22.0915 14.4552C22.2062 14.3801 22.3423 14.3445 22.479 14.354H23.938C24.375 14.354 24.468 14.573 24.375 14.885C23.8445 16.0734 23.1881 17.2015 22.417 18.25C22.26 18.49 22.197 18.615 22.417 18.896C22.562 19.115 23.073 19.542 23.417 19.948C23.9169 20.4466 24.332 21.0236 24.646 21.656C24.771 22.062 24.562 22.27 24.146 22.27Z" fill="white"/>
</svg>
        </Box>
    )
};