import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function OneIconBorder({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#EEE4FC" />
        <path
          d="M18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M16.125 14.25H19.875L17.6875 17.375C18.0472 17.375 18.4014 17.4638 18.7186 17.6334C19.0359 17.8029 19.3064 18.0482 19.5062 18.3473C19.7061 18.6464 19.8291 18.9901 19.8643 19.3481C19.8996 19.7061 19.846 20.0673 19.7083 20.3996C19.5707 20.732 19.3532 21.0252 19.0751 21.2535C18.797 21.4817 18.467 21.6378 18.1141 21.708C17.7613 21.7781 17.3967 21.7602 17.0524 21.6558C16.7082 21.5514 16.395 21.3637 16.1406 21.1094"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}
