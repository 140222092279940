import { useTheme } from "@mui/material";


interface Props {
    width: number;
}

export default function PenaLogo({ width }: Props) {
    const theme = useTheme();

    return (
        <svg style={{ minWidth: width }} width={width} viewBox="0 0 180 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_122_333)">
                <path fillRule="evenodd" clipRule="evenodd" d="M25.9138 3.31953C18.594 2.47163 13.5439 10.3345 8.84663 16.0182C4.72431 21.0062 1.6549 26.6402 1.29838 33.1042C0.919075 39.9813 2.16658 47.1434 6.85174 52.1872C11.6777 57.3826 18.9068 60.6653 25.9138 59.604C32.3391 58.6308 35.1822 51.5749 39.9658 47.1716C45.16 42.3905 54.837 40.1667 54.7027 33.1042C54.5683 26.0308 44.3552 24.6462 39.441 19.5621C34.3509 14.2959 33.1853 4.16182 25.9138 3.31953Z" fill={theme.palette.brightPurple.main} />
                <circle cx="44.126" cy="56.9181" r="4.03906" fill={theme.palette.brightPurple.main} />
                <circle cx="40.0865" cy="12.1038" r="1.53869" fill={theme.palette.brightPurple.main} />
                <path d="M64.699 31.4509C64.2503 27.0891 62.1983 23.0492 58.9405 20.1143C55.6828 17.1794 51.4514 15.5585 47.0666 15.5658C46.4441 15.5661 45.822 15.5986 45.2028 15.6634C40.8429 16.1211 36.807 18.1771 33.8735 21.4347C30.9399 24.6923 29.3165 28.9208 29.3164 33.3046V33.3046V58.6457H36.9188V47.8758C39.8912 49.9436 43.4267 51.0493 47.0476 51.0434C47.6702 51.0432 48.2923 51.0106 48.9115 50.9458C51.2282 50.7024 53.4744 50.0049 55.5216 48.8934C57.5688 47.7818 59.3771 46.2779 60.8431 44.4675C62.3091 42.6571 63.4042 40.5757 64.0658 38.3421C64.7274 36.1084 64.9425 33.7664 64.699 31.4496V31.4509ZM54.935 39.6868C54.0999 40.7241 53.0673 41.5855 51.897 42.2211C50.7266 42.8566 49.4418 43.2536 48.117 43.3891C47.7617 43.426 47.4048 43.4446 47.0476 43.4449C44.7485 43.4427 42.5183 42.6591 40.7233 41.2225C38.9282 39.7859 37.6749 37.7817 37.1689 35.5389C36.663 33.2961 36.9346 30.9479 37.9391 28.8798C38.9436 26.8117 40.6213 25.1465 42.6969 24.1576C44.7725 23.1686 47.1226 22.9147 49.3616 23.4374C51.6005 23.9601 53.5952 25.2285 55.0183 27.0343C56.4414 28.8401 57.2083 31.076 57.1932 33.3751C57.1781 35.6742 56.3818 37.8999 54.935 39.6868Z" fill={theme.palette.primary.main} />
                <path d="M84.5348 15.5659C83.9123 15.5661 83.2902 15.5987 82.671 15.6634C78.1535 16.1392 73.9907 18.3298 71.0405 21.7839C68.0903 25.2379 66.5776 29.6921 66.8141 34.2284C67.0507 38.7647 69.0184 43.0374 72.3119 46.1658C75.6053 49.2943 79.9734 51.0401 84.5158 51.0435C85.1384 51.0433 85.7605 51.0107 86.3797 50.9459C89.6368 50.5992 92.7351 49.3601 95.3331 47.3652C97.9312 45.3704 99.9282 42.6971 101.104 39.6399H92.4388L92.4033 39.6843C91.2933 41.0563 89.8444 42.1147 88.1999 42.7548C86.5554 43.395 84.7722 43.5947 83.0268 43.3343C81.2814 43.0738 79.6342 42.3622 78.2482 41.2698C76.8622 40.1774 75.7855 38.7421 75.1244 37.1058H101.859C102.422 34.5159 102.399 31.8328 101.79 29.2532C101.181 26.6736 100.003 24.2628 98.3424 22.1975C96.6813 20.1322 94.5791 18.4648 92.19 17.3173C89.8009 16.1698 87.1853 15.5714 84.5348 15.5659V15.5659ZM75.1244 29.5035C75.8165 27.8 76.9578 26.3163 78.4267 25.2104C79.8956 24.1046 81.6371 23.418 83.4655 23.224C83.8207 23.1871 84.1777 23.1685 84.5348 23.1682C86.5541 23.1648 88.528 23.7666 90.202 24.8958C91.876 26.025 93.1732 27.6299 93.9263 29.5035H75.1244Z" fill={theme.palette.primary.main} />
                <path d="M120.638 15.5659C117.732 15.5613 114.882 16.3602 112.402 17.8745V15.5659H104.8V51.0435H112.402V31.4041C112.402 29.2198 113.27 27.125 114.814 25.5805C116.359 24.0359 118.454 23.1682 120.638 23.1682C122.822 23.1682 124.917 24.0359 126.462 25.5805C128.006 27.125 128.874 29.2198 128.874 31.4041V51.0435H136.476V31.4041C136.476 27.2035 134.808 23.175 131.837 20.2048C128.867 17.2345 124.839 15.5659 120.638 15.5659Z" fill={theme.palette.primary.main} />
                <path d="M174.491 35.5715V15.5659H166.889V18.7335C163.917 16.6648 160.381 15.559 156.76 15.5659C156.138 15.5662 155.516 15.5987 154.896 15.6635C150.379 16.1392 146.216 18.3299 143.266 21.7839C140.316 25.2379 138.803 29.6921 139.039 34.2284C139.276 38.7647 141.244 43.0374 144.537 46.1659C147.831 49.2944 152.199 51.0402 156.741 51.0435C157.364 51.0432 157.986 51.0107 158.605 50.9459C163.023 50.4938 167.108 48.3888 170.04 45.0529C172.319 48.1011 175.618 50.2275 179.335 51.0435V43.0737C177.893 42.4204 176.669 41.3655 175.81 40.0351C174.951 38.7047 174.493 37.1551 174.491 35.5715ZM164.629 39.6843C163.793 40.7215 162.761 41.5828 161.59 42.2182C160.42 42.8537 159.135 43.2509 157.811 43.3867C157.455 43.4236 157.098 43.4422 156.741 43.4424C154.144 43.4423 151.646 42.4452 149.762 40.6567C147.879 38.8683 146.753 36.4251 146.619 33.8312C146.484 31.2374 147.35 28.6908 149.039 26.717C150.727 24.7433 153.109 23.4929 155.692 23.224C156.047 23.1871 156.403 23.1684 156.76 23.1682C158.674 23.1699 160.548 23.7133 162.166 24.7356C163.784 25.7579 165.079 27.2173 165.903 28.9451C166.726 30.6728 167.043 32.5983 166.817 34.4988C166.592 36.3993 165.833 38.1971 164.629 39.6843Z" fill={theme.palette.primary.main} />
                <g display={width < 120 ? "none" : undefined}>
                    <path d="M147.519 54.8936V59.1273C148.231 58.2947 149.082 57.8784 150.072 57.8784C150.58 57.8784 151.038 57.9727 151.446 58.1612C151.855 58.3497 152.161 58.5906 152.365 58.8838C152.575 59.177 152.716 59.5017 152.79 59.8578C152.868 60.2139 152.907 60.7663 152.907 61.5151V66.4085H150.7V62.0021C150.7 61.1276 150.658 60.5725 150.575 60.3369C150.491 60.1013 150.342 59.9154 150.127 59.7792C149.917 59.6378 149.653 59.5672 149.334 59.5672C148.967 59.5672 148.64 59.6562 148.352 59.8342C148.064 60.0122 147.852 60.2819 147.716 60.6432C147.585 60.9993 147.519 61.5282 147.519 62.2299V66.4085H145.312V54.8936H147.519Z" fill={theme.palette.primary.main} />
                    <path d="M160.644 66.4085V65.1597C160.341 65.6048 159.94 65.9556 159.442 66.2122C158.95 66.4688 158.429 66.5971 157.879 66.5971C157.319 66.5971 156.816 66.474 156.371 66.2279C155.926 65.9818 155.604 65.6362 155.405 65.1911C155.206 64.746 155.107 64.1307 155.107 63.3452V58.0669H157.314V61.9C157.314 63.0729 157.353 63.7929 157.432 64.06C157.515 64.3218 157.665 64.5313 157.879 64.6884C158.094 64.8402 158.366 64.9162 158.696 64.9162C159.073 64.9162 159.411 64.814 159.71 64.6098C160.008 64.4004 160.212 64.1438 160.322 63.8401C160.432 63.5311 160.487 62.7797 160.487 61.5858V58.0669H162.694V66.4085H160.644Z" fill={theme.palette.primary.main} />
                    <path d="M164.894 66.4085V54.8936H167.101V59.0409C167.782 58.2659 168.588 57.8784 169.52 57.8784C170.536 57.8784 171.376 58.2476 172.041 58.9859C172.706 59.719 173.039 60.7741 173.039 62.1513C173.039 63.5756 172.699 64.6727 172.018 65.4424C171.342 66.2122 170.52 66.5971 169.551 66.5971C169.075 66.5971 168.604 66.4792 168.138 66.2436C167.677 66.0027 167.279 65.6493 166.944 65.1832V66.4085H164.894ZM167.085 62.0571C167.085 62.9211 167.221 63.5599 167.493 63.9736C167.876 64.5601 168.384 64.8533 169.017 64.8533C169.504 64.8533 169.918 64.6465 170.258 64.2328C170.604 63.8139 170.777 63.1567 170.777 62.2613C170.777 61.3083 170.604 60.6223 170.258 60.2034C169.913 59.7792 169.47 59.5672 168.931 59.5672C168.402 59.5672 167.962 59.774 167.611 60.1877C167.26 60.5961 167.085 61.2192 167.085 62.0571Z" fill={theme.palette.primary.main} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_122_333">
                    <rect width="179.509" height="69.4872" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}