import {useMediaQuery, useTheme, Box, Typography} from "@mui/material";
import SectionStyled from './SectionStyled';
import SmiLogo from "./images/icons/SmiIcon";
import PenaLogoOnly from "./images/icons/PenaLogoOnly";

export default function Collaboration() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    return (
        <SectionStyled tag={'section'} bg={'#262835'} mwidth={'1160px'}
                       sxsect={{
                           height: isMobile ? '598px' : '369px',
                       }}
                       sxcont={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'flex-start',
                           padding: isMobile ? "30px 16px 0 16px" : (isTablet ? "0px 40px 0 40px" : 0),
                           marginBottom: isMobile ? '55px' : 0,
                           flexDirection: "column",
                           gap: "25px"
                       }}>
            <Box sx={{display: "flex", gap: isMobile ? "15px" : "30px", flexDirection: isMobile ? "column" : "row"}}>
                <Typography variant='h6' fontSize='36px' color="white" lineHeight={"normal"}>Совместный проект</Typography>
                <Box sx={{display: "flex", gap: isMobile ? "16px" : "25px"}}>
                    <SmiLogo width={isMobile ? 151 : 240}/>
                    <Typography variant='h6' fontSize='36px' color="white">и</Typography>
                    <PenaLogoOnly width={isMobile ? 90 : 138}/>
                </Box>

            </Box>
            <Box sx={{maxWidth: "712px"}}>
                <Typography sx={{
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "white"
                }} >
                    Цель двух наших сервисов предоставлять самые качественные сервисы,
                    для людей которые думают о завтрашнем дне и стремятся к тому, что бы их бизнес рос и развивался.
                    Поэтому мы решили объединить свои усилия и теперь каждый дорогой нашему сердцу читатель
                    может после запуска сервиса получить не только 14 дней <span style={{color: "#BB86FE"}} >бесплатно</span> ,
                    но и промокод со <span style={{color: "#BB86FE"}}>скидкой в 20%</span> на любой тариф сервиса PENA QUIZ
                </Typography>
            </Box>

        </SectionStyled>
    )
}