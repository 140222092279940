import { useTheme } from "@mui/material";

interface Props {
  width: number;
}

export default function PenaLogoOnly({ width }: Props) {
  const theme = useTheme();

  return (
    <svg
      style={{ minWidth: width }}
      width={width}
      height="54"
      viewBox="0 0 138 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9217 2.58184C14.2944 1.92292 10.4121 8.03332 6.80104 12.4503C3.63194 16.3265 1.27229 20.7048 0.998207 25.7281C0.706608 31.0725 1.66565 36.6383 5.26744 40.558C8.97746 44.5955 14.535 47.1465 19.9217 46.3217C24.8612 45.5654 27.0469 40.0821 30.7244 36.6603C34.7175 32.9447 42.1568 31.2166 42.0536 25.7281C41.9503 20.2313 34.0988 19.1553 30.321 15.2043C26.4078 11.1118 25.5118 3.2364 19.9217 2.58184Z"
        fill="#7E2AEA"
      />
      <ellipse
        cx="33.9215"
        cy="44.2308"
        rx="3.10509"
        ry="3.13884"
        fill="#7E2AEA"
      />
      <ellipse
        cx="30.8196"
        cy="9.4073"
        rx="1.18289"
        ry="1.19575"
        fill="#7E2AEA"
      />
      <path
        d="M49.74 24.4426C49.3951 21.053 47.8175 17.9135 45.3131 15.6327C42.8086 13.3519 39.5557 12.0923 36.1848 12.098C35.7062 12.0982 35.228 12.1235 34.752 12.1738C31.4003 12.5296 28.2976 14.1273 26.0424 16.6589C23.7872 19.1904 22.5392 22.4764 22.5391 25.8832V25.8832V45.5764H28.3835V37.2068C30.6686 38.8138 33.3865 39.673 36.1702 39.6684C36.6488 39.6682 37.1271 39.6429 37.6031 39.5926C39.3841 39.4034 41.1109 38.8614 42.6847 37.9976C44.2586 37.1338 45.6487 35.965 46.7757 34.5581C47.9027 33.1512 48.7446 31.5337 49.2532 29.7979C49.7618 28.0621 49.9272 26.2421 49.74 24.4417V24.4426ZM42.2338 30.8429C41.5918 31.6491 40.7979 32.3185 39.8982 32.8124C38.9985 33.3062 38.0108 33.6148 36.9923 33.7201C36.7192 33.7488 36.4448 33.7632 36.1702 33.7634C34.4027 33.7618 32.6883 33.1528 31.3083 32.0363C29.9283 30.9199 28.9648 29.3624 28.5758 27.6195C28.1869 25.8766 28.3957 24.0518 29.1679 22.4446C29.9401 20.8374 31.2299 19.5434 32.8255 18.7748C34.4212 18.0063 36.2279 17.809 37.9491 18.2152C39.6703 18.6214 41.2038 19.6071 42.2978 21.0104C43.3918 22.4137 43.9814 24.1513 43.9698 25.938C43.9581 27.7247 43.346 29.4543 42.2338 30.8429Z"
        fill="white"
      />
      <path
        d="M64.9893 12.098C64.5107 12.0982 64.0324 12.1235 63.5564 12.1738C60.0835 12.5435 56.8833 14.2459 54.6153 16.9301C52.3473 19.6143 51.1843 23.0758 51.3662 26.601C51.548 30.1263 53.0608 33.4467 55.5926 35.8779C58.1245 38.3091 61.4826 39.6658 64.9746 39.6684C65.4532 39.6682 65.9315 39.6429 66.4075 39.5926C68.9115 39.3231 71.2933 38.3602 73.2906 36.81C75.2879 35.2597 76.8231 33.1823 77.7272 30.8065H71.0655L71.0382 30.8409C70.1849 31.9071 69.0711 32.7296 67.8068 33.2271C66.5426 33.7246 65.1717 33.8798 63.8299 33.6774C62.4881 33.475 61.2218 32.922 60.1563 32.073C59.0908 31.2241 58.263 30.1087 57.7548 28.8371H78.3077C78.7405 26.8244 78.7222 24.7394 78.2544 22.7347C77.7866 20.73 76.881 18.8566 75.604 17.2516C74.327 15.6466 72.711 14.3508 70.8743 13.459C69.0376 12.5673 67.0268 12.1022 64.9893 12.098V12.098ZM57.7548 22.9292C58.2869 21.6054 59.1643 20.4524 60.2935 19.593C61.4228 18.7336 62.7615 18.2 64.1671 18.0492C64.4403 18.0206 64.7147 18.0061 64.9893 18.0059C66.5416 18.0033 68.0591 18.4709 69.346 19.3485C70.6329 20.226 71.6301 21.4732 72.2091 22.9292H57.7548Z"
        fill="white"
      />
      <path
        d="M92.7423 12.098C90.5085 12.0944 88.3173 12.7153 86.4108 13.892V12.098H80.5664V39.6684H86.4108V24.4062C86.4108 22.7087 87.0779 21.0808 88.2653 19.8805C89.4527 18.6802 91.0631 18.0059 92.7423 18.0059C94.4215 18.0059 96.0319 18.6802 97.2193 19.8805C98.4067 21.0808 99.0737 22.7087 99.0737 24.4062V39.6684H104.918V24.4062C104.918 21.1419 103.635 18.0112 101.352 15.703C99.0685 13.3947 95.9715 12.098 92.7423 12.098Z"
        fill="white"
      />
      <path
        d="M134.144 27.6448V12.098H128.299V14.5596C126.015 12.9519 123.296 12.0926 120.513 12.098C120.034 12.0982 119.556 12.1235 119.08 12.1738C115.607 12.5435 112.407 14.2459 110.139 16.9301C107.871 19.6143 106.708 23.0758 106.89 26.601C107.071 30.1263 108.584 33.4467 111.116 35.8779C113.648 38.3091 117.006 39.6658 120.498 39.6684C120.977 39.6682 121.455 39.6429 121.931 39.5926C125.328 39.2412 128.468 37.6054 130.722 35.013C132.474 37.3818 135.01 39.0343 137.868 39.6684V33.4749C136.759 32.9672 135.818 32.1474 135.157 31.1135C134.497 30.0796 134.145 28.8754 134.144 27.6448ZM126.562 30.841C125.92 31.647 125.126 32.3163 124.226 32.8101C123.326 33.304 122.339 33.6126 121.32 33.7181C121.047 33.7468 120.773 33.7613 120.498 33.7615C118.501 33.7614 116.581 32.9865 115.133 31.5966C113.685 30.2068 112.82 28.3081 112.716 26.2924C112.613 24.2766 113.279 22.2977 114.577 20.7638C115.875 19.2299 117.706 18.2583 119.692 18.0493C119.964 18.0206 120.238 18.0061 120.513 18.0059C121.984 18.0072 123.425 18.4295 124.669 19.2239C125.912 20.0184 126.908 21.1525 127.541 22.4952C128.174 23.8379 128.418 25.3342 128.244 26.8112C128.071 28.2881 127.488 29.6852 126.562 30.841Z"
        fill="white"
      />
    </svg>
  );
}
