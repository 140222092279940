import axios from "axios";

const domen = window.location.hostname === "localhost" ? "squiz.pena.digital" : window.location.hostname

export function sendContactFormRequest(body: {

    contact: string;
    whoami: string;
}) {
    return axios(`https://${domen}/feedback/callme`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: body,
    });
}