import ContactFormModal from "@ui_kit/ContactForm";
import "dayjs/locale/ru";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Landing from "./pages/Landing/Landing";


export default function App() {
    return (
        <>
            <ContactFormModal />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}
