import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ruRU } from '@mui/x-date-pickers/locales';
import App from "./App";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { SnackbarProvider } from 'notistack';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import "./index.css";
import lightTheme from "./utils/themes/light";
import { SWRConfig } from "swr";


dayjs.locale("ru");

const localeText = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const root = createRoot(document.getElementById("root")!);

root.render(
    <SWRConfig value={{
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    }}>
        <DndProvider backend={HTML5Backend}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru" localeText={localeText}>
                <ThemeProvider theme={lightTheme}>
                    <SnackbarProvider
                        preventDuplicate={true}
                        style={{ backgroundColor: lightTheme.palette.brightPurple.main }}
                    >
                        <CssBaseline />
                        <App />
                    </SnackbarProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </DndProvider>
    </SWRConfig>
);
