import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M20.0217 8.5V22.1552C20.0217 24.5548 18.1141 26.5 15.7609 26.5C13.4077 26.5 11.5 24.5548 11.5 22.1552C11.5 19.7556 13.4077 17.8103 15.7609 17.8103M25.5 15.3276C22.4744 15.3276 20.0217 12.8266 20.0217 9.74138"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}
