import { Box, Button, IconButton, TextField, Typography, useMediaQuery, useTheme, Modal } from "@mui/material";
import {
  sendContactForm,
  setContactFormMailField,
  setIsContactFormOpen,
  useContactFormStore,
} from "../stores/contactForm";
import { enqueueSnackbar } from "notistack";
import X from "../assets/icons/x";
import PenaLogo from "../ui_kit/PenaLogo";

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isModalOpen = useContactFormStore((state) => state.isModalOpen);
  const isSubmitDisabled = useContactFormStore((state) => state.isSubmitDisabled);
  const mail = useContactFormStore((state) => state.mail);
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  async function handleSendForm() {
    const message = await sendContactForm();

    if (message) enqueueSnackbar(message);
  }

  return (
    <Modal
      disableScrollLock
      open={isModalOpen}
      onClose={() => setIsContactFormOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        noValidate
        sx={{
          width: isMobile ? "90vw" : "600px",
          height: "474px",
          margin: "200px auto 0",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          p: upMd ? "50px" : "18px",
          pb: upMd ? "40px" : "30px",
          gap: "15px",
          borderRadius: "12px",
            boxSizing: "border-box",
          "& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled":
            {
              position: "absolute",
              top: "46px",
              margin: "0",
            },
        }}
      >
        <IconButton
          onClick={() => setIsContactFormOpen(false)}
          sx={{
            position: "absolute",
            right: "7px",
            top: "7px",
          }}
        >
          <X/>
        </IconButton>
        <Box>
          <PenaLogo width={upMd ? 233 : 196} />
        </Box>
        <Typography
          sx={{
            mt: "0px",
            mb: upMd ? "13px" : "33px",
          }}
        >
          Предрегистрация
        </Typography>
          <Typography
              sx={{textAlign: "left",
                  width: "100%",
                  fontWeight: 500
              }}
          >E-mail</Typography>
        <TextField
                  value={mail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContactFormMailField(e.target.value)}
                  placeholder="username@penahaub.com"
                  name="name"
                  fullWidth
                  sx={{
                    width: "100%",
                    // mt: "25px",
                    mb: "10px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F2F3F7",
                      height: "45px",
                      borderRadius: "10px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      borderRadius: "10px",
                      fontSize: "18px",
                      lineHeight: "21px",
                      py: 0,
                    },
                  }}
                />
        <Button
            variant='contained'
          fullWidth
          onClick={handleSendForm}
          sx={{
            py: "12px",
            "&:hover": {
            },
            "&:active": {
              color: "white",
              backgroundColor: "black",
            },
          }}
        >
          Отправить
        </Button>
        <Typography
          sx={{
            mt: "16px",
            mb: upMd ? "10px" : "33px",
              textAlign: "center",
              maxWidth: "440px"
          }}
        >
        После запуска продукта вам придет сообщение 
на указанную электронную почту
        </Typography>
      </Box>
    </Modal>
  );
};
