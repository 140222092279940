import React from 'react';
import Box from '@mui/material/Box';
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import SectionStyled from './SectionStyled';
import Link from '@mui/material/Link';
import {styled} from "@mui/material/styles";
import Notebook from "../../assets/LandingPict/notebook";
import BigBlock from "./images/bigblock.png";
import Button from "@mui/material/Button";
// import Note from './image/note.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import CalendarW from './image/calendar-W.svg'
// import CalendarP from './image/calendar-P.svg'
import CalendarIcon from "../../assets/LandingPict/calendarIcon";

// const BoxUse = styled('div')(({ theme }) => ({
//     [theme.breakpoints.down('md')]: {
//         justifyContent: 'center',
//     },
// }));
//
// const BoxButtons = styled('div')(({ theme }) => ({
//     [theme.breakpoints.down('md')]: {
//         flexDirection: 'column',
//         alignItems: 'center',
//         height: '100px',
//         width: '260px'
//     },
// }));

const BoxQuiz = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        maxWidth: '530px',
    },
}));

function Quiz () {
    return(
        <BoxQuiz
            sx={{
                maxWidth: '360px',
                height: '462px',
                backgroundColor: '#ffffff',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-around',
                alignItems: 'center',
                padding: '20px 20px 10px 20px',
                marginTop: '36px',
                gap: "25px",
                boxSizing: "border-box"
            }}
        >
            <Box>
                <Notebook/>
            </Box>
            <Box
                sx={{
                    minHeight: '86px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',}}
            >
                <Typography variant='h6'>Обучение английскому</Typography>
                <Typography color={"#4D4D4D"} >Квиз в сторис для блогера преподавателя английского языка.</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',

                    }}
                >
                    <Typography  fontWeight={600}>период:</Typography>
                    <Typography  fontWeight={600}>трафик:</Typography>
                    <Typography fontWeight={600}>результат:</Typography>

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                    }}
                >
                    <Typography>24 часа</Typography>
                    <Typography>Сторисы</Typography>
                    <Typography>386 заявок</Typography>
                </Box>

            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',}}
            >
                <Link href="#"
                      sx={{
                          color: "#7E2AEA",
                          textUnderlineOffset: "7px",
                          fontSize: '16px',
                          fontWeight: '500',
                          lineHeight: '20px',
                          textAlign: 'center'
                      }}   >
                    Подробнее →
                </Link>
            </Box>
        </BoxQuiz>
    )
}

export default function Component () {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    return (
        <SectionStyled tag={'section'} bg={'#f2f3f7'} mwidth={'1160px'}
                       sxsect={{minHeight: '809px', alignItems: 'flex-start'}}
                       sxcont={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           height: '100%',
                           padding: isMobile ? "70px 16px 70px 16px" : isTablet ? '16px 40px 50px 40px' : '81px 0 50px 0',
                       }}
        >
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        height: '50%',*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'column',*/}
            {/*        justifyContent: 'space-around',*/}
            {/*        marginTop: '34px',*/}
            {/*        width:'100%',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <BoxUse*/}
            {/*        sx={{*/}
            {/*            display: 'flex',*/}
            {/*            flexWrap: 'wrap',*/}
            {/*            justifyContent: 'space-between',*/}
            {/*            marginTop: '20px',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Box*/}
            {/*            sx={{*/}
            {/*                display: 'flex',*/}
            {/*                flexDirection: 'column',*/}
            {/*                gap: '20px'*/}

            {/*            }}*/}
            {/*        >*/}
            {/*            <Typography variant='h6' fontSize={'36px'}>Как используют квиз</Typography>*/}
            {/*            <Typography marginBottom={'10px'} fontSize={'18px'}>В крупном и малом бизнесе</Typography>*/}
            {/*        </Box>*/}
            {/*        <BoxButtons*/}
            {/*            sx={{*/}
            {/*                display: 'flex',*/}
            {/*                justifyContent: 'space-between',*/}
            {/*                alignItems: 'center',*/}
            {/*                flexWrap: 'wrap',*/}
            {/*                marginBottom: '40px',*/}
            {/*                maxWidth: '357px',*/}
            {/*                width: '100%',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <Button variant='outlined'*/}
            {/*                     sx={{*/}
            {/*                        color: '#000000',*/}
            {/*                         borderColor: '#000000',*/}
            {/*                     }}*/}
            {/*            >*/}
            {/*                Посмотреть все кейсы*/}
            {/*            </Button>*/}
            {/*            <Box>*/}
            {/*                <Button variant='contained'*/}
            {/*                        sx={{*/}
            {/*                            height: '44px',*/}
            {/*                            width: '44px',*/}
            {/*                            borderRadius: '50%',*/}
            {/*                            minWidth: '44px',*/}
            {/*                            marginRight: '10px',*/}
            {/*                        }}*/}
            {/*                ><ArrowBackIcon/></Button>*/}
            {/*                <Button variant='contained'*/}
            {/*                        sx={{*/}
            {/*                            height: '44px',*/}
            {/*                            width: '44px',*/}
            {/*                            borderRadius: '50%',*/}
            {/*                            minWidth: '44px',*/}
            {/*                        }}*/}
            {/*                ><ArrowForwardIcon/></Button>*/}
            {/*            </Box>*/}
            {/*        </BoxButtons>*/}
            {/*    </BoxUse>*/}
            {/*    <BoxUse*/}
            {/*        sx={{*/}
            {/*            display: 'flex',*/}
            {/*            justifyContent: 'space-between',*/}
            {/*            flexWrap: 'wrap',*/}
            {/*            minHeight: '530px',*/}
            {/*            marginTop: '6px',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Quiz/>*/}
            {/*        <Quiz/>*/}
            {/*        <Quiz/>*/}
            {/*    </BoxUse>*/}
            {/*</Box>*/}
            <Box
                sx={{
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        maxWidth: isMobile ? "273px" : undefined
                    }}
                    >
                    <Typography variant='h6' fontSize={'36px'} maxWidth={'420px'} lineHeight={'42.66px'}>
                        Что еще можно делать
                        с Опросником
                    </Typography>
                    <Typography  fontSize={'18px'}>
                        Изящное решение исследовательских и бизнес-задач
                    </Typography>
                </Box>
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'space-between',*/}
                {/*        width: '100%',*/}
                {/*        flexWrap: 'wrap',*/}
                {/*        marginTop: '60px',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Button*/}
                {/*            sx={{*/}
                {/*                width: '275px',*/}
                {/*                height: '66px',*/}
                {/*                borderRadius: '12px',*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: 'center',*/}
                {/*                justifyContent: 'space-around',*/}
                {/*                paddingRight: '77px',*/}
                {/*                textTransform: 'none',*/}
                {/*                backgroundColor: '#7e2aea',*/}
                {/*                color: '#ffffff',*/}
                {/*            }}*/}
                {/*    >*/}
                {/*        <CalendarIcon/>*/}
                {/*        <Typography>Исследования</Typography>*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        sx={{*/}
                {/*            width: '275px',*/}
                {/*            height: '66px',*/}
                {/*            backgroundColor: '#ffffff',*/}
                {/*            borderRadius: '12px',*/}
                {/*            display: 'flex',*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'space-around',*/}
                {/*            paddingRight: '77px',*/}
                {/*            textTransform: 'none',*/}
                {/*            color: '#4D4D4D',*/}
                {/*        }}*/}
                {/*        //variant='template'*/}
                {/*        >*/}
                {/*        <CalendarIcon/>*/}
                {/*        <Typography>Сегментация</Typography>*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        sx={{*/}
                {/*            width: '275px',*/}
                {/*            height: '66px',*/}
                {/*            backgroundColor: '#ffffff',*/}
                {/*            borderRadius: '12px',*/}
                {/*            display: 'flex',*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'space-around',*/}
                {/*            paddingRight: '77px',*/}
                {/*            textTransform: 'none',*/}
                {/*            color: '#4D4D4D',*/}
                {/*        }}*/}
                {/*        //variant='template'*/}
                {/*    >*/}
                {/*        <CalendarIcon/>*/}
                {/*        <Typography>Тестирование</Typography>*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        //variant='template'*/}
                {/*        sx={{*/}
                {/*            width: '275px',*/}
                {/*            height: '66px',*/}
                {/*            backgroundColor: '#ffffff',*/}
                {/*            borderRadius: '12px',*/}
                {/*            display: 'flex',*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'space-around',*/}
                {/*            paddingRight: '77px',*/}
                {/*            textTransform: 'none',*/}
                {/*            color: '#4D4D4D',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <CalendarIcon/>*/}
                {/*        <Typography>E-commerce</Typography>*/}
                {/*    </Button>*/}
                {/*</Box>*/}
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '462px',
                        backgroundColor: '#ffffff',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        padding: isMobile ? "0 27px 22px 20px" : isTablet ? "20px" : "22px 22px 22px 50px",
                        borderRadius: '12px',
                        marginTop: isMobile ? "35px" : "60px",
                        boxSizing: "border-box",
                        boxShadow: "0px 6.6501px 5.32008px 0px rgba(126, 42, 234, 0.03), 0px 12.52155px 10.01724px 0px rgba(126, 42, 234, 0.04), 0px 22.33631px 17.86905px 0px rgba(126, 42, 234, 0.04)",
                    }}
                >
                    <Box
                        sx={{
                            height: '70%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            paddingTop: '26px',
                            paddingLeft: isMobile ? undefined : isTablet ? '30px' : undefined

                        }}
                    >
                        <Typography variant='h6' fontSize={'36px'} lineHeight={'42.66px'} maxWidth={'455px'}>
                            Исследуйте аудиторию и проверяйте гипотезы
                        </Typography>
                        <Box
                            sx={{
                                maxWidth: isTablet ? '100%' : '416px',
                                marginTop: '19px',
                            }}
                        >
                            <Typography  fontSize={'18px'} lineHeight={'21px'} color={'#4D4D4D'}>
                                Опросник помогает проверять гипотезы
                                и принимать грамотные решения основанные
                                на данных. Создавайте опросы, собирайте обратную связь и анализируйте ответы
                                в удобной аналитике в личном кабинете.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: isTablet ? '100%' : '550px',
                            marginTop: isMobile ? undefined : isTablet ? '42px' : undefined
                        }}
                    >
                        <Box
                            component={"img"}
                            src={BigBlock}
                            sx={{
                                width: '100%',
                                height: isMobile ? '228px' : isTablet ? '671px' : '418px',
                                backgroundColor: '#c8dade',
                                borderRadius: '12px',
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </SectionStyled>
    )
}