import React from 'react';
import Box from '@mui/material/Box';
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import SectionStyled from './SectionStyled';
import OneIconBorder from "@icons/OneIconBorder";
import TwoIcon from "./images/icons/IconNumber2";
import ThreeIcon from "./images/icons/IconNumber3";
import FourIcon from "./images/icons/IconNumber4";
import Firstblock1 from "./images/firstblock1.png";
import Firstblock2 from "./images/firstblock2.png";
import Firstblock3 from "./images/firstblock3.png";
import Firstblock4 from "./images/firstblock4.png";
import Icon1 from "./images/icons/vkIcon";
import Icon2 from "./images/icons/clipIcon";
import Icon3 from "./images/icons/tiktokIcon";
import Icon4 from "./images/icons/Group133";
import Icon5 from "./images/icons/Group134";
import Icon6 from "./images/icons/Group135";
import Icon7 from "./images/icons/Group136";
import Icon8 from "./images/icons/Group137";
import Icon9 from "./images/icons/Group138";
import Icon10 from "./images/icons/Group139";
import Icon11 from "./images/icons/Group140";
import Icon12 from "./images/icons/Group141";
import Icon13 from "./images/icons/Group142";
import Icon14 from "./images/icons/Group147";
import Icon15 from "./images/icons/Group148";
import Icon16 from "./images/icons/Group149";
import Icon17 from "./images/icons/Group151";
import Icon18 from "./images/icons/Group152";
import Icon19 from "./images/icons/Group153";
import Icon20 from "./images/icons/Group154";
import Icon21 from "./images/icons/Network";

export default function HowItWorks () {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    return(
        <SectionStyled tag={'section'} bg={'#333647'} sxsect={{minHeight: '2226px', marginBottom: isMobile? 0 : "-47px"}} mwidth={'1160px'}
                       sxcont={{
                           padding: isMobile ? '65px 16px 0 16px' : (isTablet ? '80px 40px':'75px 0 0 0'),
                           boxSizing: 'border-box'
                       }}
        >
            <Box color='#ffffff'
                 sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     flexWrap: 'wrap',
                     marginBottom: isMobile ? "10px" : '65px',
                     gap: isMobile ? "30px" : undefined,
                 }}
            >
                <Box
                    sx={{
                    }}
                >
                    <Typography variant='h6' fontSize='36px' >Как это работает</Typography>
                    <Typography fontSize='18px'>Квиз как диалог</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    minHeight: '400px',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    borderRadius: '8px',
                    marginBottom: isMobile ? "26px" : (isTablet ? "60px" : "107px"),
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "100%" : isTablet ? undefined : '50%',
                        padding: '20px',
                        minHeight: '401px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        boxSizing: "border-box",
                    }}
                >
                    <Box
                        sx={{
                            height: "36px",
                            width: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: "6px",
                            background: "#EEE4FC"
                        }}
                    >
                        <OneIconBorder color={"#7E2AEA"}/>
                    </Box>
                    <Box
                        sx={{
                            minHeight: isTablet ? '121px' : '162px',
                            justifyContent: 'flex-start',
                            display: "flex",
                            flexDirection: 'column',
                            maxWidth: isMobile ? "300px" : (isTablet ? "840px" : "453px"),
                            width: "100%",
                            gap: isMobile ? 0 : "20px",
                            marginTop: isMobile ? "10px" : 0
                        }}
                    >
                        <Typography variant='h6' fontSize='36px'>
                            Начало диалога
                        </Typography>
                        <Typography  fontSize='18px'>
                            Квиз, как любезный дворецкий, в нужный момент предлагает свою помощь и начинает диалог
                        </Typography>
                    </Box>
                    {isTablet &&
                    <Box
                        component={"img"}
                        src={Firstblock1}
                        sx={{
                            backgroundColor: '#C8DADE',
                            width: isMobile ? undefined : '880px',
                            height: isMobile ? '193px': '561px',
                            borderRadius: '12px',

                        }}
                    />
                    }

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: isTablet ? '489px' : '507px',
                            height: '50%',
                            justifyContent: 'end',
                            marginTop: isMobile ? "20px" : (isTablet ? "34px" : undefined)
                        }}
                    >
                        <Typography variant='h6' fontSize='20px'>
                            квиз можно использовать
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                padding:  isTablet ? '20px 0 0 0' : '20px 20px 0 0',
                                flexDirection: isMobile ? "column" : "row",
                                gap: isMobile ? "10px" : undefined
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    // width: '126px',
                                    justifyContent: isMobile ? "start" : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon21/>
                                <Typography fontSize='18px'> на сайте</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    // width: '126px',
                                    justifyContent: isMobile ? "start" : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon2/>
                                <Typography fontSize='18px'> по прямой ссылке</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                padding: isMobile ? "10px 94px 0 0" : isTablet ? "20px 76px 0 0" : "20px 94px 0 0",
                                gap: isMobile ? "10px" : undefined
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    // width: '126px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon1/>
                                <Typography fontSize='18px'> Вконтакте</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    // width: '126px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon3/>
                                <Typography fontSize='18px'> в Тик-ток</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    component={"img"}
                    src={Firstblock1}
                    sx={{
                        display: isTablet ? "none" : "block",
                        backgroundColor: '#C8DADE',
                        width: '564px',
                        height: '360px',
                        borderRadius: '12px',

                    }}
                />
            </Box>
            <Box
                sx={{
                    minHeight: '400px',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '8px',
                    marginBottom: isMobile ? "26px" : (isTablet ? "60px" : "107px"),
                    flexWrap: 'wrap',
                    boxSizing: "border-box"
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "100%" : isTablet ? undefined : '50%',
                        minHeight: '401px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '20px',
                        boxSizing: "border-box"

                    }}
                >
                    <Box
                        sx={{
                            height: "36px",
                            width: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: "6px",
                            background: "#EEE4FC"
                        }}
                    >
                        <TwoIcon/>
                    </Box>

                    <Box
                        sx={{
                            minHeight: '40%',
                            display: 'flex',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            marginBottom: isMobile ? '23px' : isTablet ? '15px' : '90px',
                            gap: isMobile ? 0 : "20px",
                            marginTop: isMobile ? "7px" : undefined
                        }}
                    >
                        <Typography variant='h6' fontSize='36px'>
                            Задаем вопросы
                        </Typography>
                        <Typography  fontSize='18px' sx={{maxWidth: isMobile ? "335px" : isTablet ? "100%" : "444px" }} >
                            Забота заключается в том, чтобы интересоваться желаниями посетителя, задавать уточняющие вопросы и помогать делать правильный выбор
                        </Typography>
                    </Box>
                    {isTablet &&
                        <Box
                            component={"img"}
                            src={Firstblock2}
                            sx={{
                                backgroundColor: '#C8DADE',
                                width: isMobile ? undefined : '880px',
                                height: isMobile ? '193px': '561px',
                                borderRadius: '12px',
                            }}
                        />
                    }
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: isMobile ? "245px" : "500px",
                            height: '50%',
                            justifyContent: 'end',
                            marginTop: isMobile ? "40px" : isTablet ? "35px" : undefined
                        }}
                    >
                        <Typography variant='h6' fontSize='20px'>10 форматов вопроса</Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                marginTop: '15px',
                                gap: "15px"
                            }}
                        >
                            <Icon4/>
                            <Icon5/>
                            <Icon6/>
                            <Icon7/>
                            <Icon8/>
                            <Icon9/>
                            <Icon10/>
                            <Icon11/>
                            <Icon12/>
                            <Icon13/>
                        </Box>
                    </Box>
                </Box>
                <Box
                    component={"img"}
                    src={Firstblock2}
                    sx={{
                        display: isTablet ? "none" : "block",
                        backgroundColor: '#C8DADE',
                        width: '564px',
                        height: '360px',
                        borderRadius: '12px',
                    }}
                />
            </Box>
            <Box
                sx={{
                    minHeight: '400px',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '8px',
                    marginBottom: isMobile ? "26px" : (isTablet ? "60px" : "107px"),
                    flexWrap: 'wrap',
                    boxSizing: "border-box"
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "100%" : isTablet ? undefined : '50%',
                        height: '100%',
                        padding: '20px',
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                        boxSizing: "border-box"
                    }}
                >
                    <Box
                        sx={{
                            height: "36px",
                            width: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: "6px",
                            background: "#EEE4FC"
                        }}
                    >
                        <ThreeIcon/>
                    </Box>
                    <Box
                        sx={{
                            minHeight: '40%',
                            justifyContent: 'space-around',
                            display: "flex",
                            flexDirection: 'column',
                            maxWidth: isTablet ? "100%" : '388px',
                            gap: "20px"
                        }}
                    >
                        <Typography variant='h6' fontSize='36px' lineHeight='normal'>Показываем рекомендацию</Typography>
                        <Typography  fontSize='18px'>На основе ответов мы подбираем наиболее подходящие товары или услуги</Typography>
                    </Box>
                    {isTablet &&
                    <Box
                        component={"img"}
                        src={Firstblock3}
                        sx={{
                            backgroundColor: '#C8DADE',
                            width: isMobile ? undefined : '880px',
                            height: isMobile ? '193px': '561px',
                            borderRadius: '12px',
                        }}
                    />
                    }
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '497px',
                            minHeight: '50%',
                            justifyContent: 'end',
                            marginTop: isMobile ? undefined : isTablet ? " 34px" : undefined
                        }}
                    >
                        <Typography variant='h6' fontSize='20px'>точный показ результатов</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: isMobile ? 'start' : 'space-between',
                                padding: '20px 20px 0 0',
                                flexDirection: isMobile ? "column" : "row",
                                gap: isMobile ? "15px" : undefined
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon14/>
                                <Typography fontSize='18px'> логика ветвления</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon15/>
                                <Typography fontSize='18px'> калькулятор цен</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '20px 20px 0 0',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon16/>
                                <Typography fontSize='18px'> скидки</Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box
                    component={"img"}
                    src={Firstblock3}
                    sx={{
                        display: isTablet ? "none" : "block",
                        backgroundColor: '#C8DADE',
                        width: '564px',
                        height: '360px',
                        boxSizing: "border-box",
                        borderRadius: '12px',
                    }}
                />
            </Box>
            <Box
                sx={{
                    minHeight: '400px',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '8px',
                    marginBottom: isMobile ? "25px" : (isTablet ? "60px" : "126px"),
                    flexWrap: 'wrap',
                    boxSizing: "border-box"
                }}
            >
                <Box
                    sx={{
                        width: isMobile ? "100%" : isTablet ? undefined :'50%',
                        height: '100%',
                        padding: '20px',
                        boxSizing: "border-box",
                        gap: "20px",
                        display: "flex",
                        flexDirection: "column"

                    }}
                >
                    <Box
                        sx={{
                            height: "36px",
                            width: "36px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: "6px",
                            background: "#EEE4FC",
                        }}
                    >
                        <FourIcon/>
                    </Box>
                    <Box
                        sx={{
                            minHeight: '40%',
                            justifyContent: 'space-around',
                            display: "flex",
                            flexDirection: 'column',
                            maxWidth: isTablet ? "100%" :'457px',
                            marginTop: isMobile ? "-13px" : 0
                        }}
                    >
                        <Typography variant='h6' fontSize='36px'>Узнаем контакт</Typography>
                        <Typography fontSize='18px'>Только в конце диалога спрашиваем контакты. Здесь посетитель уже знает, что вы позаботились предложить ему то, что надо и с большей вероятностью оставит заявку</Typography>
                    </Box>
                    {isTablet &&
                    <Box
                        component={"img"}
                        src={Firstblock4}
                        sx={{
                            backgroundColor: '#C8DADE',
                            width: isMobile ? undefined : '880px',
                            height: isMobile ? '193px': '561px',
                            borderRadius: '12px',
                        }}
                    />
                    }
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '520px',
                            height: '50%',
                            justifyContent: 'end',
                            marginTop: isMobile ? "20px" : 0
                        }}
                    >
                        <Typography variant='h6' fontSize='20px'>Максимальная конверсия</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '20px 20px 0 0',
                                flexDirection: isMobile ? "column" : "row",
                                gap: isMobile ? "15px" : undefined
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon17/>
                                <Typography fontSize='18px'> сбор контактов</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon18/>
                                <Typography fontSize='18px'> воронки и чат-боты</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '20px 79px 0 0',
                                flexDirection: isMobile ? "column" : "row",
                                gap: isMobile ? "15px" : undefined
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon19/>
                                <Typography fontSize='18px'> интеграция с CRM</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    gap: "15px"
                                }}
                            >
                                <Icon20/>
                                <Typography fontSize='18px'> прием оплат</Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box
                    component={"img"}
                    src={Firstblock4}
                    sx={{
                        display: isTablet ? "none" : "block",
                        backgroundColor: '#C8DADE',
                        width: '564px',
                        height: '360px',

                        borderRadius: '12px',
                    }}
                />
            </Box>
        </SectionStyled>
    )

}