import { Box } from "@mui/material";


interface Props {
    color?: string;
}

export default function ({color}:Props) {

    return (
        <Box
            sx={{
                height: "36px",
                width: "36px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="6" fill="#7E2AEA"/>
<path d="M18.6561 25.9765L11.6259 19.5321C11.195 19.137 10.5 19.4428 10.5 20.0274C10.5 20.4321 10.675 20.8216 10.9915 21.1042L17.4814 27.0532C18.1312 27.6489 19.1893 27.6489 19.8391 27.0532L25.0126 22.3108C25.4214 21.9361 25.573 21.4133 25.4676 20.9288C25.3572 20.422 24.7192 20.4185 24.3369 20.769L18.6561 25.9765Z" fill="white"/>
<path d="M17.4853 23.0083C17.8104 23.3333 18.2355 23.5 18.6606 23.5C19.0857 23.5 19.5108 23.3333 19.8359 23.0083L25.0124 17.8333C25.6625 17.1833 25.6625 16.125 25.0124 15.475L18.5189 8.98333C18.2105 8.675 17.7854 8.5 17.3436 8.5H12.1671C11.2502 8.5 10.5 9.25 10.5 10.1667V15.3417C10.5 15.7833 10.675 16.2083 10.9918 16.5167L17.4853 23.0083ZM12.1671 10.1667H17.3436L23.837 16.6583L18.6606 21.8333L12.1671 15.3417V10.1667Z" fill="white"/>
<path d="M14.5 13.5C15.0523 13.5 15.5 13.0523 15.5 12.5C15.5 11.9477 15.0523 11.5 14.5 11.5C13.9477 11.5 13.5 11.9477 13.5 12.5C13.5 13.0523 13.9477 13.5 14.5 13.5Z" fill="white"/>
</svg>
        </Box>
    )
};
