import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SectionStyled from './SectionStyled';
import NavMenuItem from "@ui_kit/Header/NavMenuItem";
import QuizLogo from "./images/icons/QuizLogo";
import { useMediaQuery, useTheme } from "@mui/material";
import { setIsContactFormOpen } from "../../stores/contactForm";

const buttonMenu = ['Меню 1', 'Меню 2', 'Меню 3', 'Меню 4', 'Меню 5', 'Меню 1', 'Меню 2']

export default function Component() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000))
    const [select, setSelect] = React.useState(0)
    return (
        <SectionStyled tag={'header'} bg={'#F2F3F7'} mwidth={'1160px'}
                       sxsect={{
                           minHeight: '80px',
                           borderBottom: '1px solid #E3E3E3',
                           position: "relative",
                           padding: isMobile ? "0 16px" : isTablet ? "0 40px" : 0,
                           zIndex: 3
                       }}
                       sxcont={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           padding: 0
                       }}>
            <QuizLogo width={isMobile ? 100 : 150} />
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        maxWidth: '595px',*/}
            {/*        width: '100%',*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'space-between',*/}
            {/*        flexWrap: 'wrap',*/}
            {/*        marginRight: "138px",*/}

            {/*    }}*/}
            {/*>*/}
            {/*    {buttonMenu.map( (element, index) => (*/}
            {/*        <NavMenuItem*/}
            {/*            text={element}*/}
            {/*            // component={Link}*/}
            {/*            // to={url}*/}
            {/*            key={index}*/}
            {/*            onClick={() => {*/}
            {/*                setSelect(index);*/}
            {/*            }}*/}
            {/*            isActive={select === index}*/}
            {/*        />*/}
            {/*    ))}*/}
            {/*</Box>*/}
            <Button variant="outlined"
                onClick={() => setIsContactFormOpen(true)}
                    sx={{
                        color: 'black',
                        border: '1px solid black',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '18px',
                        lineHeight: '24px',
                        borderRadius: '8px',
                        padding: '8px 17px',
                    }}
            >Предрегистрация</Button>
        </SectionStyled>
    )
}